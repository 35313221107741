html {
  font-size: 16px;
  box-sizing: border-box;
  font-family: 'Hind Madurai', sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: 'Hind Madurai', sans-serif;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #56b4e9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Hind Madurai', sans-serif;
  font-weight: 400;
  margin: 0;
}

h1 {
  font-size: 2rem;
  letter-spacing: 0.25px;
}

h2 {
  font-size: 1.5rem;
  letter-spacing: 0px;
}

h3 {
  font-size: 1.25rem;
  letter-spacing: 0.15px;
}

h4 {
  font-size: 1rem;
  letter-spacing: 0.15px;
}

h5 {
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  font-weight: 500;
}

h6 {
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-weight: 500;
}

p {
  font-size: 1rem;
  white-space: pre-wrap;
  margin: 0px;
}

caption {
  font-size: 0.75rem;
  letter-spacing: 0.4px;
}

button {
  font-size: 0.875rem;
  letter-spacing: 1.25px;
  text-transform: none;
}

svg {
  fill: #999999;
  color: #999999;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}
